export function acAction() {
  if (document.querySelector('.js-ac')) {
    const dtEle = document.querySelectorAll('.js-acTrigger');
    const dtEleList = Array.prototype.slice.call(dtEle, 0);
    
    dtEleList.forEach((targetBox) => {
      targetBox.addEventListener('click', () => {
        targetBox.classList.toggle('is-open');
        targetBox.nextElementSibling.classList.toggle('is-open');
      })
    });
  }
}
