export function fileCheck() {
  if (document.querySelector('.js-file')) {
    const fileEle = document.getElementsByClassName('js-file');
    const length = fileEle.length;
    for (let i = 0; i < length; i++) {
      fileEle[i].onchange = function () {
        const file = this.files[0].name;
        const fileInner = document.querySelector('.js-fileInner');
        const fileLabel = document.querySelector('.js-fileLabel');
        if (!fileLabel.classList.contains("is-changed")) {
          const span = document.createElement("span");
          span.className = "p-contactForm_fileName";
          fileInner.appendChild(span);
          fileLabel.classList.add("is-changed");
        }
        // fileLabel.nextElementSibling.innerHTML = file;
        const fileName = document.querySelector('.p-contactForm_fileName');
        fileName.innerHTML = file;
      };
    }
  }

  if (document.querySelector('.mw-wp-form_file')) {
    const fileInner = document.querySelector('.js-fileInner');
    fileInner.style.display = 'none';
  }
}