export function headerAction() {
  const bodyEle = document.querySelector('body');
  const headerHamburgerEle = document.querySelector('.js-headerHamburger');
  const headerWrapEle = document.querySelector('.js-headerWrap');
  // const headerNavAnchorEle = document.querySelectorAll('a');
  // const headerNavAnchorEleList = Array.prototype.slice.call(headerNavAnchorEle, 0);

  headerHamburgerEle.addEventListener('click', () => {
    if (headerHamburgerEle.classList.contains('is-active')) {
      headerHamburgerEle.classList.remove('is-active');
      headerWrapEle.classList.remove('is-view');
      bodyEle.classList.remove('is-fixed');
    } else {
      headerHamburgerEle.classList.add('is-active');
      headerWrapEle.classList.add('is-view');
      bodyEle.classList.add('is-fixed');
    }
  });

  // headerNavAnchorEleList.forEach((targetBox) => {
  //   targetBox.addEventListener('click', () => {
  //     headerHamburgerEle.classList.remove('is-active');
  //     headerWrapEle.classList.remove('is-view');
  //     bodyEle.classList.remove('is-fixed');
  //   });
  // });
}
