import { throttle } from 'lodash';

export function lodashAction() {
  window.addEventListener(
    'scroll',
    _.throttle(
      (e) => {
        scrollDownHide();
        scrollWaveView();
        scrollMvAfter();
        return false;
      },
      50,
      { trailing: true, leading: true }
    )
  );

  // ページ読み込み時にスクロールトップボタンがkvより下にあれば表示
  window.addEventListener('load', () => {
    scrollDownHide();
    scrollWaveView();
    scrollMvAfter();
  });
}

function scrollDownHide() {
  const scroll = window.pageYOffset;

  if (document.querySelector('.js-scrollDown')) {
    const scrollDown = document.querySelector('.js-scrollDown');

    // スクロール量がkvの高さを超えたらページトップボタンを表示
    if (scroll > window.outerHeight) {
      scrollDown.classList.add('is-hide');
    } else {
      scrollDown.classList.remove('is-hide');
    }
  }
}

function scrollWaveView() {
  const scroll = window.pageYOffset;

  if (document.querySelector('.js-waveView')) {
    const heroArea = document.querySelector('.js-heroArea');
    const waveView = document.querySelector('.js-waveView');

    // スクロール量がkvの高さを超えたらページトップボタンを表示
    if (scroll > heroArea.clientHeight / 2) {
      waveView.classList.add('is-view');
    } else {
      waveView.classList.remove('is-view');
    }
  }
}

function scrollMvAfter() {
  const scroll = window.pageYOffset;

  if (document.querySelector('.js-scrollDown')) {
    const indexMain = document.querySelector('.js-indexMain');
    const introHeight = indexMain.clientHeight;

    // スクロール量がkvの高さを超えたらページトップボタンを表示
    if (scroll > 200) {
      indexMain.classList.add('is-scrolled');
    }
    if (scroll < 10) {
      indexMain.classList.remove('is-scrolled');
    }
  }
}
