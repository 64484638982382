export function btnJudge() {
  const linkEle = document.querySelectorAll('a');
  const linkEleList = Array.prototype.slice.call(linkEle, 0);
  
  /** is-active linkEle */
  linkEleList.forEach((targetBox) => {
    if (!targetBox.classList.contains('js-btn')) {
      targetBox.classList.add('js-btn');
    }
  });
  
  /** mw form file delete btn */
  if (document.querySelector('.mwform-file-delete')) {
    const fileDeleteEle = document.querySelector('.mwform-file-delete');
    fileDeleteEle.classList.add('js-btn');
  }
}
