import ScrollMagic from 'scrollmagic';

export function scrollmagic() {
  let controller = new ScrollMagic.Controller();

  indexMagic();
  indexMagicLeave();
  magicEnter();
  // magicLeave();

  function magicLeave() {
    let magicLeave = document.querySelectorAll('.js-magicLeave');
    for (let i = 0; i < magicLeave.length; i++) {
      let magicLeaveEle = magicLeave[i];

      new ScrollMagic.Scene({
        triggerElement: magicLeaveEle,
        triggerHook: 'onLeave',
        offset: 0,
      })
        .setClassToggle(magicLeaveEle, 'is-animated')
        .addTo(controller);
    }
  }

  function magicEnter() {
    let magicEnter = document.querySelectorAll('.js-magicEnter');
    for (let i = 0; i < magicEnter.length; i++) {
      let magicEnterEle = magicEnter[i];

      new ScrollMagic.Scene({
        triggerElement: magicEnterEle,
        triggerHook: 'onEnter',
        offset: 200,
        reverse: false,
      })
        .setClassToggle(magicEnterEle, 'is-animated')
        .addTo(controller);
    }
  }

  function indexMagic() {
    let indexSection = document.querySelectorAll('.js-indexSection');
    for (let i = 0; i < indexSection.length; i++) {
      let indexSectionEle = indexSection[i];

      new ScrollMagic.Scene({
        triggerElement: indexSectionEle,
        triggerHook: 'onEnter',
        offset: 50,
      })
        .setClassToggle('.l-header, main, .p-indexHero_cont--first, .p-indexHero.js-indexSection, .p-indexHero_scroll .c-scroll, .p-indexHero_cont .p-indexHero_leadItem:nth-of-type(1), .p-indexHero_cont .p-indexHero_leadItem:nth-of-type(2), .p-indexHero_cont .p-indexHero_sub, .p-indexHero_cont .p-indexHero_copy, .p-indexHero_cont .p-indexHero_link', 'is-animated')
        .addTo(controller);
    }
  }

  function indexMagicLeave() {
    let indexSectionLeave = document.querySelectorAll('.js-indexSectionLeave');
    for (let i = 0; i < indexSectionLeave.length; i++) {
      let indexSectionLeaveEle = indexSectionLeave[i];

      new ScrollMagic.Scene({
        triggerElement: indexSectionLeaveEle,
        triggerHook: 'onLeave',
        offset: 0,
      })
        .setClassToggle('.l-header', 'is-animated')
        .addTo(controller);
    }
  }
}
