export function mouseStalker() {
  document.addEventListener('DOMContentLoaded', () => {
    if (!navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
      const body = document.body;
      const stalker = document.createElement('div');
      const btns = document.querySelectorAll('.js-btn');
      const STALKER_SIZE = 30;
      const STALHER_COLOR = 'rgba(0, 183, 169,.8)';
      const SCALE_SIZE = 3;
      const SCALE_DURATION = 0.3;

      ({
        init() {
          const self = this;
          stalker.id = 'js-stalker';
          stalker.className = 'c-stalker';
          stalker.style.width = STALKER_SIZE + 'px';
          stalker.style.height = STALKER_SIZE + 'px';
          stalker.style.backgroundColor = STALHER_COLOR;
          body.appendChild(stalker);
          body.addEventListener('mousemove', self.onMouseMove);
          for (let i = 0; i < btns.length; i++) {
            btns[i].addEventListener('mouseenter', self.onMouseEnter);
            btns[i].addEventListener('mouseleave', self.onMouseLeave);
          }
        },
        onMouseMove(e) {
          TweenMax.to(stalker, 0.4, {
            x: e.clientX - STALKER_SIZE / 2,
            y: e.clientY - STALKER_SIZE / 2,
          });
        },
        onMouseEnter() {
          TweenMax.to(stalker, SCALE_DURATION, {
            scale: SCALE_SIZE,
          });
        },
        onMouseLeave() {
          TweenMax.to(stalker, SCALE_DURATION, {
            scale: 1,
          });
        },
      }.init());
    }
  });
}
